import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.css";
import * as webUni from "@/utils/webUni.js";
// 移动端适配库
import "lib-flexible/flexible";
import "./permission";
// 浏览器重置样式
import "@/assets/css/reset.css";
import "@/assets/font/iconfont.css";
import "@/assets/font_njewxmseebs/iconfont.css";
import "@/assets/font_zzjewu63ujj/iconfont.css";
import "vant/lib/index.css";
import voiceInputButton from "voice-input-button2";
Vue.use(Antd);
Vue.prototype.$webUni = webUni;

console.log(router);
Vue.config.productionTip = false;
new Vue({
  el: "#app",
  router,
  store,
  render: (h) => h(App),
});
Vue.use(voiceInputButton, {
  appId: "82bcc575", // 您申请的语音听写服务应用的ID
  apiKey: "18391a3f39decf8f1953c18c402fde7c", // 您开通的语音听写服务的 apiKey
  apiSecret: "ZjIyZDU5NzVmNjcyZjMwM2RjOWIwZjBh", // 您开通的语音听写服务的 apiSecret
  color: "#fff", // 按钮图标的颜色
  tipPosition: "top", // 提示条位置
});
