import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);

export const constantRoutes = [
  // {
  //   path: "/",
  //   name: "index",
  //   component: () =>
  //     import(/* webpackChunkName: 'lxapplication' */ "@/views/index.vue"),
  // },
  // {
  //   path: "/index",
  //   name: "index",
  //   component: () =>
  //       import(
  //           /* webpackChunkName: 'lxapplication' */ "@/views/index.vue"
  //           ),
  // },
  // 零星工程
  // 申报初审
  {
    path: "/lxapplication",
    name: "lxapplication",
    component: (resolve) =>
      require(["@/views/lxApplication/lxApplication.vue"], resolve), //懒加载

    // component: () =>
    //     import(
    //         /* webpackChunkName: 'lxapplication' */ "@/views/lxApplication/lxApplication.vue"
    //         ),
  },
  // 初审详情
  {
    path: "/lxapplication/lxdeclarationdetails",
    name: "lxdeclarationdetails",
    component: (resolve) =>
      require([
        "@/views/lxApplication/lxDeclarationdetails/lxDeclarationdetails.vue",
      ], resolve), //懒加载

    // component: () =>
    //     import(
    //         /* webpackChunkName: 'lxdeclarationdetails' */ "@/views/lxApplication/lxDeclarationdetails/lxDeclarationdetails.vue"
    //         ),
  },
  // 合同汇签/分管领导审批
  {
    path: "/lxLeader",
    name: "lxleader",
    component: (resolve) => require(["@/views/lxLeader/lxleader.vue"], resolve), //懒加载

    // component: () =>
    //     import(
    //         /* webpackChunkName: 'lxleader' */ "@/views/lxLeader/lxleader.vue"
    //         ),
  },
  // 汇签详情
  {
    path: "/lxLeader/lxleaderdetails",
    name: "lxleaderdetails",
    component: (resolve) =>
      require([
        "@/views/lxLeader/lxLeaderdetails/lxLeaderdetails.vue",
      ], resolve), //懒加载

    // component: () =>
    //     import(
    //         /* webpackChunkName: 'lxleaderdetails' */ "@/views/lxLeader/lxLeaderdetails/lxLeaderdetails.vue"
    //         ),
  },
  // 合同清单
  {
    path: "/lxcontractlist",
    name: "lxcontractlist",
    component: (resolve) =>
      require(["@/views/lxContractList/lxcontractlist.vue"], resolve), //懒加载

    // component: () =>
    //     import(
    //         /* webpackChunkName: 'lxcontractlist' */ "@/views/lxContractList/lxcontractlist.vue"
    //         ),
  },
  // 清单详情页
  {
    path: "/lxcontractlist/lxcontracdetails",
    name: "lxContracdetails",
    component: (resolve) =>
      require([
        "@/views/lxContractList/lxContracdetails/lxcontracdetails.vue",
      ], resolve), //懒加载

    // component: () =>
    //     import(
    //         /* webpackChunkName: 'lxContracdetails' */ "@/views/lxContractList/lxContracdetails/lxcontracdetails.vue"
    //         ),
  },
  // 审批进度查询
  {
    path: "/lxapproveprogress",
    name: "lxapproveprogress",
    component: (resolve) =>
      require(["@/views/lxApproveProgress/lxapproveprogress.vue"], resolve), //懒加载

    // component: () =>
    //     import(
    //         /* webpackChunkName: 'lxapproveprogress' */ "@/views/lxApproveProgress/lxapproveprogress.vue"
    //         ),
  },
  // 审批进度详情页
  {
    path: "/lxapproveprogress/lxapdetails",
    name: "lxapdetails",
    component: (resolve) =>
      require([
        "@/views/lxApproveProgress/lxAPdetails/lxapdetails.vue",
      ], resolve), //懒加载
    // component: () =>
    //     import(
    //         /* webpackChunkName: 'lxapdetails' */ "@/views/lxApproveProgress/lxAPdetails/lxapdetails.vue"
    //         ),
  },
  // 工程部
  // 合同汇签/分管领导审批/领导批示
  {
    path: "/gcLeader",
    name: "gcleader",
    component: (resolve) => require(["@/views/gcLeader/gcleader.vue"], resolve), //懒加载

    // component: () =>
    //     import(
    //         /* webpackChunkName: 'gcleader' */ "@/views/gcLeader/gcleader.vue"
    //         ),
  },
  // 汇签详情
  {
    path: "/gcLeader/gcleaderdetails",
    name: "gcleaderdetails",
    component: (resolve) =>
      require([
        "@/views/gcLeader/gcLeaderdetails/gcLeaderdetails.vue",
      ], resolve), //懒加载

    // component: () =>
    //     import(
    //         /* webpackChunkName: 'gcleaderdetails' */ "@/views/gcLeader/gcLeaderdetails/gcLeaderdetails.vue"
    //         ),
  },
  // 合同清单
  {
    path: "/gccontractlist",
    name: "gccontractlist",
    component: (resolve) =>
      require(["@/views/gcContractList/gccontractlist.vue"], resolve), //懒加载
    //
    // component: () =>
    //     import(
    //         /* webpackChunkName: 'gccontractlist' */ "@/views/gcContractList/gccontractlist.vue"
    //         ),
  },
  // 清单详情页
  {
    path: "/gccontractlist/gccontracdetails",
    name: "gcContracdetails",
    component: (resolve) =>
      require([
        "@/views/gcContractList/gcContracdetails/gccontracdetails.vue",
      ], resolve), //懒加载

    // component: () =>
    //     import(
    //         /* webpackChunkName: 'gcContracdetails' */ "@/views/gcContractList/gcContracdetails/gccontracdetails.vue"
    //         ),
  },
  // 审批进度查询
  {
    path: "/gcapproveprogress",
    name: "gcapproveprogress",
    component: (resolve) =>
      require(["@/views/gcApproveProgress/gcapproveprogress.vue"], resolve), //懒加载

    // component: () =>
    //     import(
    //         /* webpackChunkName: 'gcapproveprogress' */ "@/views/gcApproveProgress/gcapproveprogress.vue"
    //         ),
  },
  // 审批进度详情页
  {
    path: "/gcapproveprogress/gcapdetails",
    name: "gcapdetails",
    component: (resolve) =>
      require([
        "@/views/gcApproveProgress/gcAPdetails/gcapdetails.vue",
      ], resolve), //懒加载

    // component: () =>
    //     import(
    //         /* webpackChunkName: 'gcapdetails' */ "@/views/gcApproveProgress/gcAPdetails/gcapdetails.vue"
    //         ),
  },

  // 附件预览
  {
    path: "/filePreview",
    name: "filePreview",
    component: (resolve) => require(["@/views/filePreview.vue"], resolve), //懒加载

    // component: () =>
    //     import(
    //         /* webpackChunkName: 'gcapproveprogress' */ "@/views/gcApproveProgress/gcapproveprogress.vue"
    //         ),
  },
];
export default new Router({
  mode: "history", // 去掉url中的#
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes,
});
